import React from "react";
import styled from "styled-components";
import * as theme from "@theme";

const StarComponent: React.FC<TProps> = ({ count }) => {
  const countActive = Math.ceil(count);

  return (
    <div className="ml-2 d-flex justify-content-between pb-2">
      {countActive &&
        [...Array(5)].map((x, index) => (
          <Star active={countActive < index + 1} key={"star" + index}>
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
                fill=""
              />
            </svg>
          </Star>
        ))}
    </div>
  );
};

export default StarComponent;

export const Star = styled.span<{ active: boolean }>`
  position: relative;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 30px;
  svg path {
    fill: ${({ active }) =>
      active ? `${theme.colors.whitegray}` : `${theme.colors.lightblue}`};
  }
  @media ${theme.breakpoints.desktop} {
    margin: 0 2.5px;
  }
`;

type TProps = {
  count: number;
};
