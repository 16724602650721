import styled from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";

export const Rate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const CluchSliderItem = styled.div`
  background: ${theme.colors.white};
  border-radius: 12px;
  width: 400px;
  height: 400px;
  padding: 72px 20px 53px;
  @media ${theme.breakpoints.desktop} {
    padding: 72px 20px 53px;
    width: 400px;
    height: 400px;
  }
`;
