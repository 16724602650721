import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";
import { Col, Container } from "react-bootstrap";

export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 968px;
  margin: 0 auto;
`;

export const MarkerWrapper = styled.div<{
  coords: { top: number; left: number };
}>`
  position: absolute;
  cursor: pointer;
  top: ${({ coords }) => coords.top}%;
  left: ${({ coords }) => coords.left}%;
`;

export const MarkerContent = styled.div`
  ${SH.Text.TextBodyCss};
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: -160px;
  height: auto;
  width: 375px;
  transition: transform 0.3s ease-out;
  transform: scale(0);
  opacity: 0;
  border-radius: 25px;
  padding: 10px 20px;
  text-align: center;
  background: white;

  color: #2e2e2e;

  &:hover {
    transform: scaleY(1);
    opacity: 1;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MarkerImage = styled.img`
  position: relative;
  z-index: 0;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.15);
    & ~ div {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const Ul = styled.ul`
  ${SH.Text.TextBodyCss};
  list-style: none;
  margin: 0;
`;

export const Project = styled.li`
  position: relative;
  margin-top: 10px;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    left: -2rem;
    top: 0.75rem;
    background: #0462b9;
    height: 7px;
    width: 7px;
    border-radius: 50%;
  }
`;

export const PlacesWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const CountryCol = styled(Col)<{ isExpanded: boolean }>`
  transition: height 0.5s ease-in-out;
  padding-bottom: 30px;
  overflow: hidden;
  margin-bottom: 51px;
  &:last-child {
    margin-bottom: 0;
  }

  &:nth-of-type(even) {
    background-color: ${theme.colors.whitegray};
  }
`;

export const ContainerClientMapMobile = styled(Container)`
  background-color: ${theme.colors.white};
`;

export const ButtonShowMore = styled.button<{ isExpanded: boolean }>`
  ${SH.Text.TextButtonCss};
  border: 0;
  position: absolute;
  display: ${({ isExpanded = false }) => (isExpanded ? `none` : `block`)};
  height: 30px;
  background-color: transparent;
  bottom: 0px;
  width: 100%;
  text-align: right;
  left: 0;
  text-transform: uppercase;
`;
