import { useStaticQuery, graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

const useFetchExperise = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiExpertisePage(sort: { fields: id }) {
        edges {
          node {
            caseStudy {
              description
              id
              title
              subtitle
              file {
                localFile {
                  publicUrl
                }
                name
              }
              image {
                localFile {
                  publicUrl
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      layout: FIXED
                      width: 545
                      placeholder: TRACED_SVG
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              textButtonUrl
              buttonUrl
              textButtonFile
            }
            clutch {
              averangeRate
              description
              clutchBox {
                projectSummary
                rate
                review
                id
              }
            }
            OurAward {
              title
              subtitle
              link
              id
              image {
                alternativeText
                localFile {
                  publicUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchExperise;

type TData = {
  allStrapiExpertisePage: {
    edges: {
      node: {
        caseStudy: {
          title: string;
          id: number;
          subtitle: string;
          description: string;
          image: {
            localFile: {
              publicUrl: string;
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
              } | null;
            };
            alternativeText: string;
          };
          textButtonFile: string;
          file: {
            localFile: {
              publicUrl: string;
            };
            name: string;
          };
          textButtonUrl: string;
          buttonUrl: string;
        }[];
        clutch: {
          averangeRate: number;
          description: string;
          clutchBox: TNode[];
        };
        OurAward: {
          title: string;
          subtitle: string;
          link: string;
          id: number;
          image: {
            alternativeText: string;
            localFile: {
              publicUrl: string;
            };
          };
        }[];
      };
    }[];
  };
};

export type TNode = {
  review: string;
  rate: number;
  averageRate?: number;
  id: string;
  projectSummary: string;
  reviewerJob?: string;
};
