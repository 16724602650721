export interface IMarkerCordinates {
  id: number;
  city: string;
  content: string[];
  coords: {
    top: number;
    left: number;
  };
}

export interface IMarkerMobile {
  name: string;
  places: IMarkerCordinates[];
}

export enum PLACES {
  MAX = 4,
}

export type TPropsContinent = {
  name: string;
  places: IMarkerCordinates[];
};

export type TProps = {
  places: IMarkerCordinates[];
  setIsExpanded: (parms: boolean) => void;
  isExpanded: boolean;
};
