import styled, { css } from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";
import { Col } from "react-bootstrap";

export const Image = styled(SH.Image)`
  object-fit: cover;
  height: 345px;
  width: 375px;
  z-index: 1;
  @media ${theme.breakpoints.desktop} {
    height: 545px;
    width: 545px;
  }
`;

export const Wrapperbox = styled(SH.Wrapper.Box)`
  height: 720px;
  padding: 0;
  &:nth-of-type(even) {
    padding: 0;
  }
  @media ${theme.breakpoints.desktop} {
    height: 545px;
    padding: 0;
    &:nth-of-type(even) {
      padding: 0;
    }
  }
`;

export const WrapperText = styled.div`
  padding: 40px 30px;
`;

export const Loader = styled(SH.Loader)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
`;
