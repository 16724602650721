import { Row, Col, Container } from "react-bootstrap";
import React, { useState } from "react";
import * as SH from "@shared";
import { markerMobile } from "./ClientMap.constant";
import * as Types from "./ClientMap.types";
import * as S from "./ClientMap.styles";

const Places = ({ places, isExpanded, setIsExpanded }: Types.TProps) => {
  let counterProjects = 0;
  return (
    <>
      {places.map((place) =>
        place.content.map((content, index) => {
          counterProjects++;
          if (!isExpanded && counterProjects - 1 > Types.PLACES.MAX) return;

          return (
            <React.Fragment key={"places" + index}>
              {!isExpanded && counterProjects - 1 === Types.PLACES.MAX ? (
                <S.ButtonShowMore
                  onClick={() => setIsExpanded(true)}
                  isExpanded={isExpanded}
                >
                  show more
                </S.ButtonShowMore>
              ) : (
                <S.PlacesWrapper>
                  <SH.Text.TextBody className="mb-0">
                    {content}
                  </SH.Text.TextBody>
                  <SH.Text.TextButton
                    className="text-uppercase text-right mb-1"
                    colorComponent="darkblue"
                  >
                    {place.city}
                  </SH.Text.TextButton>
                </S.PlacesWrapper>
              )}
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

const Continent = ({ name, places }: Types.TPropsContinent) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <S.CountryCol isExpanded={isExpanded} xs={{ span: 10, offset: 1 }}>
      <SH.Text.Header3 className="my-4" colorComponent="lightblue">
        {name}
      </SH.Text.Header3>
      <Places
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        places={places}
      />
    </S.CountryCol>
  );
};

export const ClientMapMobile = () => (
  <S.ContainerClientMapMobile>
    <Row>
      {markerMobile.map((item, index) => (
        <Continent key={index} name={item.name} places={item.places} />
      ))}
    </Row>
  </S.ContainerClientMapMobile>
);
