import styled from "styled-components";
import * as theme from "@theme";
import { Col } from "react-bootstrap";
import ClutchSVG from "./assets/Clutch.svg";

export const ClutchReviewCol = styled(Col)`
  padding: 16 31px 47px;
  background: url(${ClutchSVG});
  background-repeat: no-repeat;
  background-position: 50% 20%;

  @media ${theme.breakpoints.desktop} {
    padding: 66px 20px 49px;
  }
`;

export const SliderCol = styled(Col)``;

export const SliderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
  height: 400px;
  width: 400px;
  padding-left: 20px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  width: calc(100% + 300px);
  min-width: 860px;

  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    transform: translate(0, 18px);
  }
  .slick-slide {
    transform: translate(0, 50px);
    transition: transform 1s 0s ease-out;
    opacity: 0.5;
  }
  .slick-current {
    opacity: 1;
    transform: translate(0);
  }

  @media ${theme.breakpoints.desktop} {
    position: absolute;

    width: calc(100% + 200px);
    height: 100%;
    overflow-x: hidden;
  }
`;
