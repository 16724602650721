import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import { Col } from "react-bootstrap";

export const ColImage = styled(Col)`
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -15px;
    height: calc(100% + 30px);
    width: 250%;
    background-color: ${theme.colors.whiteblue};
    z-index: -1;
  }
`;
