import React from "react";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";
import { Row, Container, Col } from "react-bootstrap";
import useFetchCaseStudy from "../ExpertisePage.hooks";
import * as S from "./Content.styles";

export const Awards = () => {
  const { data } = useFetchCaseStudy();
  const awards = data.allStrapiExpertisePage.edges[0].node.OurAward;
  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            {!!awards &&
              awards.map(({ id, title, subtitle, link, image }) => (
                <SH.Wrapper.Box key={id} heightComponent={heightComponent}>
                  <Row className="h-100 justify-content-between row-box">
                    <Col lg="5">
                      <SH.Text.Header2>{title}</SH.Text.Header2>
                      <SH.Text.MarkdownTextBody>
                        {ReactHtmlParser(subtitle)}
                      </SH.Text.MarkdownTextBody>
                      {!!link && (
                        <SH.Button.Href
                          className="mt-5 mx-auto mx-lg-0"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${link}`}
                        >
                          watch the presentation
                        </SH.Button.Href>
                      )}
                    </Col>
                    <S.ColImage
                      className="d-flex justify-content-center align-self-lg-center"
                      lg="6"
                    >
                      <a href={image?.localFile.publicUrl}>
                        <SH.Image
                          heightImg={heightImg}
                          src={image?.localFile.publicUrl}
                          alt={image?.alternativeText}
                        />
                      </a>
                    </S.ColImage>
                  </Row>
                </SH.Wrapper.Box>
              ))}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  desktop: 610,
  mobile: 450,
};

const heightComponent = {
  desktop: 650,
  mobile: 800,
};
