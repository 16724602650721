//@ts-ignore

import React from "react";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import useFetchCaseStudy from "../ExpertisePage.hooks";
import * as S from "./Content.styles";
import ReactHtmlParse from "react-html-parser";

export const Content = () => {
  const { data } = useFetchCaseStudy();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col xs="11" lg="10">
            {!!data.allStrapiExpertisePage.edges[0].node.caseStudy &&
              data.allStrapiExpertisePage.edges[0].node.caseStudy.map(
                ({
                  title,
                  description,
                  id,
                  image,
                  subtitle,
                  file,
                  textButtonUrl,
                  textButtonFile,
                  buttonUrl,
                }) => (
                  <S.Wrapperbox key={id}>
                    <Row className="h-100 justify-content-between row-box">
                      <Col lg="6" xl="5" className="">
                        <S.WrapperText>
                          <SH.Text.SubTitle2 colorComponent="lightblue">
                            {subtitle}
                          </SH.Text.SubTitle2>
                          <SH.Text.Header2 className="py-4">
                            {title}
                          </SH.Text.Header2>
                          <SH.Text.MarkdownTextBody className="pb-3">
                            {ReactHtmlParse(description)}
                          </SH.Text.MarkdownTextBody>
                          {!!file && (
                            <SH.Button.Href
                              className="mt-3 mb-5 mx-auto mx-lg-0"
                              href={`${file.localFile.publicUrl}`}
                              download={file.name}
                            >
                              {textButtonFile || "download case study"}
                            </SH.Button.Href>
                          )}
                          {!!buttonUrl && (
                            <SH.Button.Href
                              className="mt-3 mb-5 mx-auto mx-lg-0"
                              href={`${buttonUrl}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {textButtonUrl || "read more"}
                            </SH.Button.Href>
                          )}
                        </S.WrapperText>
                      </Col>
                      <Col
                        lg="6"
                        className="d-flex flex-column justify-content-end justify-content-sm-center justify-content-lg-end align-items-center"
                      >
                        <SH.Img.Plugin
                          heightImg={heightImg}
                          widthImg={widthImg}
                          src={image?.localFile.publicUrl}
                          gatsbyImageSrc={
                            image?.localFile.childImageSharp?.gatsbyImageData
                          }
                          alt={image?.alternativeText}
                          objectFit="cover"
                          component={S.Image}
                        ></SH.Img.Plugin>
                      </Col>
                    </Row>
                  </S.Wrapperbox>
                )
              )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  desktop: 545,
  mobile: 375,
};

const widthImg = {
  desktop: 545,
  mobile: 375,
};
