import React, { useRef } from "react";
import { useResize } from "@hooks";
import { Col, Row, Container } from "react-bootstrap";
import * as S from "./ClientMap.styles";
import * as SH from "@shared";
import { Marker } from "./Marker";
import { ClientMapMobile } from "./ClientMap.mobile";
import Map from "./assets/Map.svg";
import MapMobile from "./assets/MapMobile.svg";
import { StaticImage } from "gatsby-plugin-image";
export const ClientMap = () => {
  const { isMobile } = useResize();

  return (
    <>
      <SH.Wrapper.Primary
        paddingTop={paddingTop}
        className="pb-5"
        colorComponent="whitegray"
      >
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={{ span: 12 }} lg="5" xl="4">
              <SH.Text.Header2>
                unique experience helping clients
              </SH.Text.Header2>
              <SH.Text.TextBody className="py-5">
                We have helped many companies grow their business, simplify
                business processes and stay ahead of the competition. Now we
                share these success stories with you.
              </SH.Text.TextBody>
            </Col>
            <Col
              xs={{ span: 12 }}
              className="d-flex flex-column justify-content-center align-self-lg-center px-0"
            >
              <S.MapWrapper>
                {!isMobile ? (
                  <>
                    <SH.Image heightImg={heightImg} src={Map} alt="map" />
                    <Marker />
                  </>
                ) : (
                  <SH.Image src={MapMobile} heightImg={heightImg} />
                )}
              </S.MapWrapper>
            </Col>
          </Row>
        </Container>
      </SH.Wrapper.Primary>
      {isMobile && <ClientMapMobile />}
    </>
  );
};

const heightImg = {
  desktop: 538.01,
  mobile: 220,
};

const paddingTop = {
  desktop: 30,
  mobile: 105,
};
