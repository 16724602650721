import { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useResize } from "@hooks";
import { TData } from "./ClutchSlider.types";

const useClutch = () => {
  const { width, isMobile } = useResize();
  const refSliderCol = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!refSliderCol.current || typeof window === "undefined") return;

    if (isMobile) {
      const slickSlider = refSliderCol.current.querySelector(".slick-slider");
      refSliderCol.current.style.height = window
        .getComputedStyle(slickSlider as Element)
        .getPropertyValue("height");
    } else {
      refSliderCol.current.style.height = "initial";
    }
  }, [refSliderCol, width, isMobile]);

  return { refSliderCol };
};

export default useClutch;
