import { IMarkerCordinates, IMarkerMobile } from "./ClientMap.types";

export const markersCoord: IMarkerCordinates[] = [
  // europe
  {
    id: 1,
    city: "Haga, Netherlands",
    content: ["Fast forms creation app for conferences"],
    coords: { top: 27, left: 47.5 },
  },
  {
    id: 2,
    city: "Berlin, Germany",
    content: [
      "Search Engine for Bundestag Documents (scraping and solr - Policyleads)",
    ],
    coords: { top: 26, left: 49.5 },
  },
  {
    id: 3,
    city: "London/Bristol, UK",
    content: [
      "Online Travel Agency (with channel managers)",
      "Multiple Branding Apps",
      "Small Offerings within social groups app",
      "Encryption module for safe online file storing with 2 way encription",
    ],
    coords: { top: 24.5, left: 46 },
  },
  {
    id: 4,
    city: "Cracow, Poland",
    content: [
      "Museum sightseeing app aggregator (Guides4Art)",
      "BeerApp - beer finding app  (WIP)",
      "Smog Monitoring app with machine learning predictions (R&D)",
    ],
    coords: { top: 25, left: 51.5 },
  },
  // australia
  {
    id: 5,
    city: "Sydney, Australia",

    content: [
      "Work Culture Management App (Heelix)",
      "Delivery Driver Coordination App (HandyView)",
      "Delivery Management Automated QA",
    ],
    coords: { top: 76, left: 88 },
  },
  {
    id: 6,
    city: "Perth, Australia",
    content: ["Lottery Branding App MVP"],
    coords: { top: 76, left: 79 },
  },
  // USA
  {
    id: 7,
    city: "New York, US",
    content: ["CRM for the matchmaking industry"],
    coords: { top: 30, left: 28.5 },
  },
  {
    id: 8,
    city: "Chicago, US",
    content: ["Voice record/share plugin for Chrome MVP"],
    coords: { top: 30, left: 21 },
  },
  {
    id: 9,
    city: "Austin, US",
    content: [
      "Administration panel for a self maintnance supercomputer (Kiosk app)",
    ],
    coords: { top: 41, left: 19 },
  },
  {
    id: 10,
    city: "San Francisco, US",
    content: ["Social network for the electronic music industry (Tronic.fm) "],
    coords: { top: 35, left: 12 },
  },
  // WORLDWIDE
  {
    id: 11,
    city: "Worldwide",

    content: ["AWS World Wide DevOps"],
    coords: { top: 95, left: 49 },
  },
];

export const markerMobile: IMarkerMobile[] = [
  {
    name: "Europe",
    places: markersCoord.slice(0, 4),
  },
  {
    name: "Australia",
    places: markersCoord.slice(4, 6),
  },
  {
    name: "USA",
    places: markersCoord.slice(6, 10),
  },
  {
    name: "WORLDWIDE",
    places: markersCoord.slice(10),
  },
];
