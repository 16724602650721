import React, { useState } from "react";
import { markersCoord } from "./ClientMap.constant";
import * as S from "./ClientMap.styles";
import * as SH from "@shared";
import MarkerSVG from "./assets/Marker.svg";

export const Marker = () => {
  return (
    <>
      {markersCoord.map((marker) => (
        <S.MarkerWrapper key={marker.id} coords={marker.coords}>
          <S.MarkerImage src={MarkerSVG} alt="marker" />
          <S.MarkerContent className="d-flex flex-column justify-content-center">
            <SH.Text.TextBody>{marker.city}</SH.Text.TextBody>
            <S.Ul>
              {marker.content.map((project, index) => (
                <S.Project key={"project" + index}>{project}</S.Project>
              ))}
            </S.Ul>
          </S.MarkerContent>
        </S.MarkerWrapper>
      ))}
    </>
  );
};
