import React, { useRef } from "react";
import useClutch from "./ClutchSlider.hooks";
import useFetchClutch from "../ExpertisePage.hooks";
import Slider, { Settings } from "react-slick";
import { Row, Col, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import * as S from "./ClutchSlider.styles";
import * as SH from "@shared";
import * as theme from "@theme";
import CluchSliderItem from "./CluchSliderItem";
import Star from "./ClutchStar";

export const ClutchReview = () => {
  const target = useRef(null);
  const { refSliderCol } = useClutch();
  const { data } = useFetchClutch();

  return (
    <SH.Wrapper.Primary ref={target}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            <SH.Wrapper.Box colorComponent="whitegray" heightComponent={height}>
              <Row className="h-100 overflow-y-hidden">
                <S.ClutchReviewCol
                  lg={{ span: 5 }}
                  className="d-flex flex-column justify-content-around"
                >
                  <SH.Text.SubTitle2 colorComponent="lightblue">
                    here’s what our clients say about us
                  </SH.Text.SubTitle2>
                  <SH.Text.Header2>Clutch reviews</SH.Text.Header2>
                  <SH.Text.MarkdownTextBody className="pt-3">
                    {ReactHtmlParser(
                      data.allStrapiExpertisePage.edges[0].node.clutch
                        .description
                    )}
                  </SH.Text.MarkdownTextBody>
                  <div className="d-flex justify-content-between justify-content-lg-start align-items-center">
                    <SH.Text.Header3>
                      {data.allStrapiExpertisePage.edges[0].node.clutch.averangeRate.toFixed(
                        2
                      )}
                    </SH.Text.Header3>
                    <Star
                      count={
                        data.allStrapiExpertisePage.edges[0].node.clutch
                          .averangeRate
                      }
                    />

                    <SH.Text.SubTitle3 className="ml-1 ml-sm-5 mb-0 text-nowrap">
                      {
                        data.allStrapiExpertisePage.edges[0].node.clutch
                          .clutchBox.length
                      }{" "}
                      clutch reviews
                    </SH.Text.SubTitle3>
                  </div>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <SH.Button.Href
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://clutch.co/profile/coderspeak"
                    >
                      VISIT CLUTCH SITE
                    </SH.Button.Href>
                  </div>
                </S.ClutchReviewCol>
                <S.SliderCol
                  ref={refSliderCol}
                  lg={{ span: 7 }}
                  className="d-lg-flex justify-content-center align-items-center"
                >
                  <S.SliderWrapper>
                    <Slider {...sliderSettings}>
                      {data.allStrapiExpertisePage.edges[0].node.clutch.clutchBox.map(
                        (node, index) => (
                          <CluchSliderItem key={node.id} {...node} />
                        )
                      )}

                      {data.allStrapiExpertisePage.edges[0].node.clutch.clutchBox.map(
                        (node, index) => (
                          <CluchSliderItem key={node.id} {...node} />
                        )
                      )}
                    </Slider>
                  </S.SliderWrapper>
                </S.SliderCol>
              </Row>
            </SH.Wrapper.Box>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const sliderSettings: Settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnFocus: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const height = {
  desktop: 545,
  mobile: 770,
};
