import React from "react";
import * as SH from "@shared";
import * as S from "./CluchSliderItem.styles";
import * as theme from "@theme";
import { TNode } from "../ExpertisePage.hooks";
import Star from "./ClutchStar";

const CluchSliderItem = (node: TNode) => {
  const { review, rate, projectSummary } = node;

  return (
    <S.CluchSliderItem>
      <div>
        <SH.Text.SubTitle2>{review}</SH.Text.SubTitle2>
        <SH.Text.SubTitle3 colorComponent="lightblue">
          project summary:
        </SH.Text.SubTitle3>
        <SH.Text.TextBody>{projectSummary}</SH.Text.TextBody>
      </div>
      <S.Rate>
        <SH.Text.Header3>{rate.toFixed(2)}</SH.Text.Header3>
        <Star count={rate} />
      </S.Rate>
    </S.CluchSliderItem>
  );
};

export default CluchSliderItem;
